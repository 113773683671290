@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    --toastify-toast-width: 460px;
}

.main {
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    margin: 0 auto;
}
